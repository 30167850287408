<template>
    <div>
        <v-data-table
            show-expand
            single-expand
            item-key="SearchId"
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            :loading="loading"
            loading-text="Retrieving orders ... please wait"
            :loader-height="6"
            :hide-default-footer="loading"
        >
            <template v-slot:top>
                <v-toolbar color="secondary" dark>
                    <user-select v-model="selectedUser" :items="users">
                        <v-toolbar-title
                            class="mt-2"
                        >Orders to process for {{$_.get(selectedUser,'name','')}}</v-toolbar-title>
                        <v-chip small class="ml-2 mt-3 secondary darken-2">{{orders.length}}</v-chip>
                    </user-select>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.stop="$emit('refresh')" class="mr-4">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                    <v-btn class="secondary darken-2" @click.stop="handleAction(null)">
                        <v-icon class="mr-2">mdi-message-text</v-icon>Send Text
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.Property="{item}">
                <div class="d-flex flex-column my-1">
                    <span
                        class="font-weight-medium alternate1--text text--darken-1"
                    >{{item.Property.name}}</span>
                    <div>
                        <span class="font-weight-light">Company:</span>
                        <span class="font-weight-medium ml-1">{{item.Property.company}}</span> |
                        <span class="font-weight-light">Unit:</span>
                        <span class="font-weight-medium ml-1">{{item.Property.unit}}</span> |
                        <span class="font-weight-light">Rent:</span>
                        <span class="font-weight-medium ml-1">{{item.Property.rent}}</span>
                    </div>
                </div>
            </template>
            <template v-slot:item.Applicant="{item}">
                <div class="d-flex flex-column my-1">
                    <span
                        class="font-weight-medium alternate1--text text--darken-1"
                    >{{item.Applicant.name}}</span>
                    <div>
                        <span class="font-weight-light">Type:</span>
                        <span class="font-weight-medium ml-1">{{item.Applicant.type}}</span> |
                        <span class="font-weight-light">Order:</span>
                        <span class="font-weight-medium ml-1">{{item.Applicant.order}}</span>
                    </div>
                </div>
            </template>
            <template v-slot:item.SearchId="{item}">
                <span>{{item.SearchId}}</span>
                <psi-copy-button
                    :value="item.SearchId"
                    dense
                    btn-class="my-n2 ml-2"
                    label="Search ID"
                ></psi-copy-button>
            </template>
            <template v-slot:item.actions="{item}">
                <psi-action-drop-down
                    :heading="`Text Message Actions`"
                    :items="getActions(item)"
                    btn-label="Actions"
                    width="400"
                    @action="handleAction($event)"
                ></psi-action-drop-down>
            </template>
            <template v-slot:expanded-item="{ headers , item}">
                <td :colspan="headers.length">
                    <order-details :item="item"></order-details>
                </td>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600" ref="dialog">
            <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
            <send-text-message
                v-if="dialog"
                :title="title"
                :contact="contact"
                :phoneNumber="phoneNumber"
                :order="order"
                @close="dialog=false"
            ></send-text-message>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "orders-list",
    components: {
        OrderDetails: () => import("./OrderDetails"),
        UserSelect: () => import("./UserSelect"),
        SendTextMessage: () =>
            import("@components/messages/components/SendTextMessage"),
    },
    props: {
        inputUser: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            dialog: false,
            selectedUser: { ...this.inputUser },
            contact: {},
            order: {},
            title: "",
            phoneNumber: "",
            headers: [
                {
                    text: "Search ID",
                    align: "start",
                    sortable: true,
                    value: "SearchId",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Applicant",
                    align: "start",
                    sortable: false,
                    value: "Applicant",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },

                {
                    text: "Status",
                    align: "start",
                    sortable: true,
                    value: "Status",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Created",
                    align: "start",
                    sortable: true,
                    value: "Created",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Property",
                    align: "start",
                    sortable: false,
                    value: "Property",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },

                {
                    text: "Actions",
                    align: "start",
                    sortable: false,
                    value: "actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    watch: {
        user: {
            immediate: true,
            handler(val) {
                this.selectedUser = val;
            },
        },
        selectedUser() {
            this.$emit("filter", this.selectedUser.email);
        },
    },
    computed: {
        ...mapGetters("Order", ["orders", "loading"]),
        ...mapGetters("User", ["user", "users"]),
    },
    methods: {
        getActions(item) {
            let actions = [
                {
                    title: "View all messages",
                    icon: "mdi-forum",
                    type: "view",
                    to: {
                        path: "/messages/view",
                        query: { search_id: item.SearchId },
                    },
                },
                {
                    divider: true,
                },
            ];
            item.Contacts.forEach((contact) => {
                actions.push({
                    title: `${contact.name} | ${contact.type} | ${contact.phone}`,
                    icon: "mdi-comment-account",
                    contact,
                    type: "text",
                    SearchId: item.SearchId,
                });
            });
            return actions;
        },
        handleAction(item) {
            if (item == null || item.type == "text") {
                this.contact = item ? item.contact : null;
                this.title = "Send text message";
                this.phoneNumber = "";
                if (item) {
                    this.title += ` ${this.$_.get(
                        this.contact,
                        "name",
                        ""
                    )} ( ${this.$_.get(this.contact, "phone", "")} )`;
                    this.phoneNumber = this.$_.get(this.contact, "phone", "");

                    this.order = this.orders.find(
                        (val) => val.SearchId == item.SearchId
                    );
                }
                this.dialog = true;
            }
        },
    },
};
</script>

<style scoped>
</style>