var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          "show-expand": "",
          "single-expand": "",
          "item-key": "SearchId",
          headers: _vm.headers,
          items: _vm.orders,
          "items-per-page": 10,
          loading: _vm.loading,
          "loading-text": "Retrieving orders ... please wait",
          "loader-height": 6,
          "hide-default-footer": _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { color: "secondary", dark: "" } },
                  [
                    _c(
                      "user-select",
                      {
                        attrs: { items: _vm.users },
                        model: {
                          value: _vm.selectedUser,
                          callback: function($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser"
                        }
                      },
                      [
                        _c("v-toolbar-title", { staticClass: "mt-2" }, [
                          _vm._v(
                            "Orders to process for " +
                              _vm._s(_vm.$_.get(_vm.selectedUser, "name", ""))
                          )
                        ]),
                        _c(
                          "v-chip",
                          {
                            staticClass: "ml-2 mt-3 secondary darken-2",
                            attrs: { small: "" }
                          },
                          [_vm._v(_vm._s(_vm.orders.length))]
                        )
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-4",
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("refresh")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-refresh")])],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "secondary darken-2",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.handleAction(null)
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v("mdi-message-text")
                        ]),
                        _vm._v("Send Text ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.Property",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "d-flex flex-column my-1" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium alternate1--text text--darken-1"
                    },
                    [_vm._v(_vm._s(item.Property.name))]
                  ),
                  _c("div", [
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v("Company:")
                    ]),
                    _c("span", { staticClass: "font-weight-medium ml-1" }, [
                      _vm._v(_vm._s(item.Property.company))
                    ]),
                    _vm._v(" | "),
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v("Unit:")
                    ]),
                    _c("span", { staticClass: "font-weight-medium ml-1" }, [
                      _vm._v(_vm._s(item.Property.unit))
                    ]),
                    _vm._v(" | "),
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v("Rent:")
                    ]),
                    _c("span", { staticClass: "font-weight-medium ml-1" }, [
                      _vm._v(_vm._s(item.Property.rent))
                    ])
                  ])
                ])
              ]
            }
          },
          {
            key: "item.Applicant",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "d-flex flex-column my-1" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium alternate1--text text--darken-1"
                    },
                    [_vm._v(_vm._s(item.Applicant.name))]
                  ),
                  _c("div", [
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v("Type:")
                    ]),
                    _c("span", { staticClass: "font-weight-medium ml-1" }, [
                      _vm._v(_vm._s(item.Applicant.type))
                    ]),
                    _vm._v(" | "),
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v("Order:")
                    ]),
                    _c("span", { staticClass: "font-weight-medium ml-1" }, [
                      _vm._v(_vm._s(item.Applicant.order))
                    ])
                  ])
                ])
              ]
            }
          },
          {
            key: "item.SearchId",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", [_vm._v(_vm._s(item.SearchId))]),
                _c("psi-copy-button", {
                  attrs: {
                    value: item.SearchId,
                    dense: "",
                    "btn-class": "my-n2 ml-2",
                    label: "Search ID"
                  }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("psi-action-drop-down", {
                  attrs: {
                    heading: "Text Message Actions",
                    items: _vm.getActions(item),
                    "btn-label": "Actions",
                    width: "400"
                  },
                  on: {
                    action: function($event) {
                      return _vm.handleAction($event)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c(
                  "td",
                  { attrs: { colspan: headers.length } },
                  [_c("order-details", { attrs: { item: item } })],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          ref: "dialog",
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c("send-text-message", {
                attrs: {
                  title: _vm.title,
                  contact: _vm.contact,
                  phoneNumber: _vm.phoneNumber,
                  order: _vm.order
                },
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }